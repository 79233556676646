* {
  overflow-wrap: anywhere;
}

.disable__click {
  pointer-events: none !important;
}

.disable__click_fade {
  pointer-events: none !important;
  opacity: 0.6;
}



/* For Webkit browsers (e.g., Chrome, Safari) */
.thin__scrollbar::-webkit-scrollbar {
  width: 5px;
  /* Width of the entire scrollbar */
  height: 5px;
  /* Height of the entire scrollbar */
}

.thin__scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(100, 100, 100, 0.5);
  /* Color of the scrollbar thumb */
  border-radius: 10px;
  /* Rounded corners for the scrollbar thumb */
}

.thin__scrollbar::-webkit-scrollbar-track {
  background: rgba(200, 200, 200, 0.3);
  /* Color of the scrollbar track */
}