.confirm_dialogue_box {
  width: 460px;
  min-height: 180px;
  background-color: white;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;
  outline: none;
  padding: 25px;
}
.confirm_dialogue_boxicon {
  width: 100%;
  display: flex;
  justify-content: center;
}
.confirm_dialogue_boxicon > * {
  color: #0c68f3af;
  font-size: 45px !important;
  font-weight: 400 !important;
}
.confirm_dialogue_text {
  width: 100%;
  height: 60%;
  display: block;
  /* justify-content: center;
  align-items: center; */
  text-align: center;
  padding: 0;
  font-size: 24px;
  color: grey;
  margin: 20px 0;
}
.confirm_dialogue_btns {
  width: 100%;
  height: 40%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.confirm_dialogue_confirm,
.confirm_dialogue_cancel {
  width: 80px;
  height: 35px;
  background: #0c69f3;
  color: white;

  border-radius: 5px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  cursor: pointer;
}
.confirm_dialogue_cancel {
  color: #454545;
  background: #d9d9d9;
}
@media (max-width: 980px) {
  .confirm_dialogue_box {
    width: 320px;
  }
  .confirm_dialogue_text {
    font-size: 18px;
  }
  .confirm_dialogue_confirm,
  .confirm_dialogue_cancel {
    width: 60px;
    height: 30px;
    border-radius: 5px;
    font-size: 12px;
  }
}
